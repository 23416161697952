import { makeAutoObservable } from 'mobx';

import globalAppStore from './global-app-store';

import { mapLotToApi } from 'src/adapters';
import { doPost, fetchLotById } from 'src/api';
import { ApiResponseStatus, ApiRoute, LotTypeShown, Rights } from 'src/constants';
import { checkIsPersonValidValue, getAddress, getTextError } from 'src/utils';
import type {
  IContactPerson,
  IContactPersonFields,
  IContactPersonFilled,
  ILot,
} from 'src/interfaces';

interface IListTouchedInputs {
  deedOwner: string[];
  purchaser: string[];
}

const DEFAULT_CONTACT_PERSON_VALUE: IContactPerson = {
  address: null,
  addressString: '',
  apartment: '',
  dateOfBirth: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
};

const DEFAULT_LIST_TOUCHED_INPUTS = { deedOwner: [], purchaser: [] };

//Do not change the order of elements in an array
const CONTACT_PERSON_LABEL: string[] = [
  'Name',
  'Address',
  'Date of Birth',
  'Phone Number',
  'Email',
];

const LIST_REQUIRED_FIELDS: IContactPersonFields[] = [
  'address',
  'addressString',
  'dateOfBirth',
  'email',
  'firstName',
  'lastName',
  'phone',
];

class ReservationStore {
  init(id: string) {
    this.resetStore();
    void this.load(id);
  }

  deedOwner: IContactPerson = DEFAULT_CONTACT_PERSON_VALUE;
  isLoading: boolean = false;
  isPageNotFound: boolean = false;
  isSameAsPurchaser = true;
  lot: ILot | null = null;
  listTouchedInputs: IListTouchedInputs = DEFAULT_LIST_TOUCHED_INPUTS;
  purchaser: IContactPerson = DEFAULT_CONTACT_PERSON_VALUE;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDeedOwner(deedOwner: IContactPerson) {
    this.deedOwner = deedOwner;
  }

  setIsSameAsPurchaser(isSameAsPurchaser: boolean) {
    this.isSameAsPurchaser = isSameAsPurchaser;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setLot(lot: ILot | null) {
    this.lot = lot;
  }

  setListTouchedInputs(listTouchedInputs: IListTouchedInputs) {
    this.listTouchedInputs = listTouchedInputs;
  }

  setPageNotFound(isPageNotFound: boolean) {
    this.isPageNotFound = isPageNotFound;
  }

  setPurchaser(purchaser: IContactPerson) {
    this.purchaser = purchaser;
  }

  get lotDetails() {
    if (!this.lot) {
      return [];
    }

    const { bank, block, mausoleum, number, rights, row, section, tier, type } = this.lot;

    return [
      { label: 'Cemetery', value: globalAppStore.cemeteryInfo.name },
      ...(mausoleum
        ? [
            { label: 'Mausoleum', value: mausoleum.name },
            ...(bank ? [{ label: 'Bank', value: bank }] : []),
            ...(tier ? [{ label: 'Tier', value: tier }] : []),
            ...(row ? [{ label: 'Row', value: row }] : []),
            { label: `${LotTypeShown[type]} Number`, value: number },
          ]
        : [
            { label: 'Section', value: section ?? '' },
            ...(block ? [{ label: 'Block', value: block }] : []),
            { label: 'Lot Number', value: number },
          ]),

      { label: 'Lot Type', value: LotTypeShown[type] },
      { label: 'Rights', value: Rights[rights] },
    ];
  }

  get isFormValid() {
    const isPurchaserValid = LIST_REQUIRED_FIELDS.every((field: IContactPersonFields) =>
      checkIsPersonValidValue(field, this.purchaser[field])
    );

    if (this.isSameAsPurchaser) {
      return isPurchaserValid;
    }

    const isDeedOwnerValid = LIST_REQUIRED_FIELDS.every((field: IContactPersonFields) =>
      checkIsPersonValidValue(field, this.deedOwner[field])
    );

    return isPurchaserValid && isDeedOwnerValid;
  }

  async load(id: string) {
    this.setLoading(true);

    const lot = await fetchLotById(id);
    if (lot) {
      this.setLot(lot);
    } else {
      this.setLot(null);
      this.setPageNotFound(true);
    }

    this.setLoading(false);
  }

  async sendRequest(onSuccess: () => void, onFail: () => void) {
    const purchaser = this.purchaser;
    const deedOwner = this.isSameAsPurchaser ? purchaser : this.deedOwner;

    try {
      if (!this.lot?.id || !purchaser.address || !deedOwner.address) {
        return;
      }

      this.setLoading(true);

      const payload = mapLotToApi(
        purchaser as IContactPersonFilled,
        deedOwner as IContactPersonFilled,
        this.lot.id
      );

      const result = await doPost(ApiRoute.LOT_REQUEST, payload);

      if (result.status === ApiResponseStatus.SUCCESS) {
        onSuccess();
      }
    } catch (err: any) {
      console.log(err);
      onFail();
    }
    this.setLoading(false);
  }

  checkIsTouchedInput(field: string, isDeedOwner: boolean) {
    return this.listTouchedInputs[isDeedOwner ? 'deedOwner' : 'purchaser'].includes(field);
  }

  getPurchaserInfo(isDeedOwner = false) {
    return CONTACT_PERSON_LABEL.map((label: string) => {
      return { label, value: this.getListValue(label, isDeedOwner) };
    });
  }

  updateListTouchedInputs(field: string, isDeedOwner: boolean) {
    const objectField = isDeedOwner ? 'deedOwner' : 'purchaser';
    const newData = [...this.listTouchedInputs[objectField]];

    if (newData.indexOf(field) === -1) {
      newData.push(field);

      this.setListTouchedInputs({
        ...this.listTouchedInputs,
        [objectField]: newData,
      });
    }
  }

  updateValue<K extends IContactPersonFields>(isDeedOwner: boolean) {
    return (field: K) => (value: IContactPerson[K]) => {
      const newData = { ...(isDeedOwner ? this.deedOwner : this.purchaser) };
      newData[field] = value;

      if (isDeedOwner) {
        return this.setDeedOwner(newData);
      }

      this.setPurchaser(newData);
    };
  }

  getListValue(label: string, isDeedOwner: boolean) {
    const { address, apartment, dateOfBirth, email, firstName, lastName, phone } = isDeedOwner
      ? this.deedOwner
      : this.purchaser;

    switch (label) {
      case CONTACT_PERSON_LABEL[0]:
        return `${firstName.trim()} ${lastName.trim()}`;
      case CONTACT_PERSON_LABEL[1]:
        return address ? getAddress(address, apartment) : '';
      case CONTACT_PERSON_LABEL[2]:
        return `${dateOfBirth}`;
      case CONTACT_PERSON_LABEL[3]:
        return `${phone}`;
      case CONTACT_PERSON_LABEL[4]:
        return `${email.trim()}`;
      default:
        return '';
    }
  }

  getValidationProps(field: IContactPersonFields, isDeedOwner: boolean) {
    const person = isDeedOwner ? this.deedOwner : this.purchaser;
    const value = person[field];
    const isTouchedInput =
      field === 'apartment' ? false : this.checkIsTouchedInput(field, isDeedOwner);
    const isValid = !isTouchedInput || checkIsPersonValidValue(field, value);

    return { isValid, textError: !isValid ? getTextError(field, value) : '' };
  }

  getAddressValidationProps(isDeedOwner: boolean) {
    const person = isDeedOwner ? this.deedOwner : this.purchaser;
    const isTouchedInput = this.checkIsTouchedInput('addressString', isDeedOwner);
    const stringTextError = checkIsPersonValidValue('addressString', person['addressString'])
      ? ''
      : getTextError('addressString', person['addressString']);
    const geoTextError = checkIsPersonValidValue('address', person['address'])
      ? ''
      : getTextError('address', person['address']);
    const textError = stringTextError || geoTextError;
    const isValid = !isTouchedInput || !textError;

    return { isValid, textError };
  }

  resetStore() {
    this.setPageNotFound(false);
    this.setIsSameAsPurchaser(true);
    this.setListTouchedInputs(DEFAULT_LIST_TOUCHED_INPUTS);
    this.setPurchaser(DEFAULT_CONTACT_PERSON_VALUE);
  }
}

export default new ReservationStore();
