import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useLocalization } from 'src/contexts/localization-context';
import type { ButtonProps } from './button.props';

import './button.scss';

function Button({
  className,
  href = '',
  icon,
  isDisabled,
  label,
  onClick,
  size,
  desktopSize,
  tag = 'button',
  target,
  theme: buttonTheme,
  type = 'button',
  ...props
}: ButtonProps) {
  const { getLocalization } = useLocalization();
  const buttonClasses = classNames(
    'button',
    buttonTheme && `button_${buttonTheme}`,
    size && `button_size_${size}`,
    desktopSize && `button_desktop-size_${desktopSize}`,
    icon && 'button_with-icon',
    isDisabled && 'button_disabled',
    className
  );

  const renderInnerElements = () => (
    <span className="button__wrapper">
      {icon && (
        <span className="button__icon" role="presentation">
          {icon}
        </span>
      )}
      <span className="button__label">{label}</span>
    </span>
  );

  switch (tag) {
    case 'button':
      return (
        <button
          aria-label={label}
          className={buttonClasses}
          disabled={isDisabled}
          onClick={onClick}
          type={type}
          {...props}
        >
          {renderInnerElements()}
        </button>
      );
    case 'link':
      return (
        <Link
          aria-label={
            target === '_blank' ? `${label} ${getLocalization('opens in new window')}` : label
          }
          className={buttonClasses}
          onClick={onClick}
          target={target || '_self'}
          to={href}
          {...props}
        >
          {renderInnerElements()}
        </Link>
      );
    case 'a':
      return (
        <a
          aria-label={
            target === '_blank' ? `${label} ${getLocalization('opens in new window')}` : label
          }
          className={buttonClasses}
          href={href}
          onClick={onClick}
          rel="noopener noreferrer"
          target={target || '_blank'}
          {...props}
        >
          {renderInnerElements()}
        </a>
      );
    default:
      return null;
  }
}

export default Button;
