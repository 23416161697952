import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import AddressInput from 'src/components/ui/address-input';
import Checkbox from 'src/components/ui/checkbox';
import Input from 'src/components/ui/input';

import popupStore from 'src/stores/popup-store';
import reservationStore from 'src/stores/reservation-store';

import { mapGoogleAddress } from 'src/adapters';
import { InputMaxLength, Mask, MaskPlaceholder, PopupName } from 'src/constants';
import { useLocalization } from 'src/contexts/localization-context';
import useIsTablet from 'src/hooks/use-is-tablet';
import { getGeocodeAddress } from 'src/utils';
import type { IGoogleGeocodeAddress } from 'src/interfaces';
import type { PersonFormProps } from './person-form.props';

import './person-form.scss';

const PersonForm = observer(({ isDeedOwner = false }: PersonFormProps) => {
  const {
    deedOwner,
    isSameAsPurchaser,
    purchaser,
    getValidationProps,
    getAddressValidationProps,
    setIsSameAsPurchaser,
    updateListTouchedInputs,
    updateValue,
  } = reservationStore;
  const { getLocalization } = useLocalization();
  const isTablet = useIsTablet();
  const formTitle = isDeedOwner ? getLocalization('Rights Holder') : getLocalization('Purchaser');
  const isInputDisabled = isDeedOwner && isSameAsPurchaser;
  const titleWrapperClasses = classNames(
    'person-form__title-wrapper',
    isDeedOwner && 'person-form__title-wrapper_deed-owner'
  );
  const personFormClasses = classNames('person-form', isDeedOwner && 'person-form_deed-owner');
  const { addressString, apartment, dateOfBirth, email, firstName, lastName, phone } =
    isDeedOwner && !isSameAsPurchaser ? deedOwner : purchaser;

  const handleChange = useMemo(() => updateValue(isDeedOwner), [isDeedOwner, updateValue]);

  const listHandleChange = useMemo(
    () => ({
      lastName: handleChange('lastName'),
      firstName: handleChange('firstName'),
      address: handleChange('address'),
      addressString: handleChange('addressString'),
      apartment: handleChange('apartment'),
      dateOfBirth: handleChange('dateOfBirth'),
      phone: handleChange('phone'),
      email: handleChange('email'),
    }),
    [handleChange]
  );

  const listHandleBlur = useMemo(
    () => ({
      lastName: () => updateListTouchedInputs('lastName', isDeedOwner),
      firstName: () => updateListTouchedInputs('firstName', isDeedOwner),
      addressString: () => updateListTouchedInputs('addressString', isDeedOwner),
      dateOfBirth: () => updateListTouchedInputs('dateOfBirth', isDeedOwner),
      phone: () => updateListTouchedInputs('phone', isDeedOwner),
      email: () => updateListTouchedInputs('email', isDeedOwner),
    }),
    [isDeedOwner, updateListTouchedInputs]
  );

  const handleBlurAddress = useCallback(
    (value: string) => {
      updateListTouchedInputs('addressString', isDeedOwner);
      void getGeocodeAddress(
        value,
        (googleGeocodeAddress?: IGoogleGeocodeAddress) => {
          listHandleChange.address(mapGoogleAddress(googleGeocodeAddress));
          listHandleChange.addressString(googleGeocodeAddress?.formatted_address ?? '');
        },
        () => popupStore.showPopup(PopupName.WARN)
      );
    },
    [isDeedOwner, listHandleChange, updateListTouchedInputs]
  );

  const phoneTextClasses = classNames(
    'person-form__phone-text',
    !getValidationProps('phone', isDeedOwner).isValid && 'person-form__phone-text_hidden'
  );

  return (
    <section
      className={personFormClasses}
      aria-label={`${formTitle} - ${getLocalization('Personal Information')}`}
    >
      <div className={titleWrapperClasses}>
        <h2 className="person-form__title">{formTitle}</h2>

        {isDeedOwner ? (
          <Checkbox
            id="isSameAsPurchaser"
            isChecked={isSameAsPurchaser}
            label={getLocalization('Same as Purchaser')}
            onChange={setIsSameAsPurchaser}
          />
        ) : null}
      </div>

      <div className="person-form__inputs-wrapper" role="form">
        {isTablet ? (
          <>
            <Input
              autoComplete="given-name"
              isDisabled={isInputDisabled}
              label={`${getLocalization('First Name')}*`}
              maxLength={InputMaxLength.MAX_LENGTH_NAME}
              onBlur={listHandleBlur.firstName}
              onChange={listHandleChange.firstName}
              value={firstName}
              {...getValidationProps('firstName', isDeedOwner)}
            />

            <Input
              autoComplete="family-name"
              isDisabled={isInputDisabled}
              label={`${getLocalization('Last Name')}*`}
              maxLength={InputMaxLength.MAX_LENGTH_NAME}
              onBlur={listHandleBlur.lastName}
              onChange={listHandleChange.lastName}
              value={lastName}
              {...getValidationProps('lastName', isDeedOwner)}
            />
          </>
        ) : (
          <>
            <Input
              autoComplete="family-name"
              isDisabled={isInputDisabled}
              label={`${getLocalization('Last Name')}*`}
              maxLength={InputMaxLength.MAX_LENGTH_NAME}
              onBlur={listHandleBlur.lastName}
              onChange={listHandleChange.lastName}
              value={lastName}
              {...getValidationProps('lastName', isDeedOwner)}
            />

            <Input
              autoComplete="given-name"
              isDisabled={isInputDisabled}
              label={`${getLocalization('First Name')}*`}
              maxLength={InputMaxLength.MAX_LENGTH_NAME}
              onBlur={listHandleBlur.firstName}
              onChange={listHandleChange.firstName}
              value={firstName}
              {...getValidationProps('firstName', isDeedOwner)}
            />
          </>
        )}

        <AddressInput
          apartmentValue={apartment}
          className="person-form__address-wrapper"
          isDisabled={isInputDisabled}
          label={`${getLocalization('Address')}*`}
          onApartmentChange={listHandleChange.apartment}
          onBlur={handleBlurAddress}
          onChange={listHandleChange.addressString}
          value={addressString}
          {...getAddressValidationProps(isDeedOwner)}
        />

        <Input
          className="person-form__row"
          autoComplete="bday"
          isDisabled={isInputDisabled}
          inputMode="numeric"
          label={`${getLocalization('Date of Birth')}*`}
          mask={Mask.DATE}
          maskPlaceholder={MaskPlaceholder.DATE}
          onBlur={listHandleBlur.dateOfBirth}
          onChange={listHandleChange.dateOfBirth}
          value={dateOfBirth}
          {...getValidationProps('dateOfBirth', isDeedOwner)}
        />

        <div className="person-form__phone-wrapper">
          <Input
            autoComplete="tel-local"
            isDisabled={isInputDisabled}
            inputMode="tel"
            label={`${getLocalization('Phone')}*`}
            mask={Mask.PHONE}
            maskPlaceholder={MaskPlaceholder.PHONE}
            onBlur={listHandleBlur.phone}
            onChange={listHandleChange.phone}
            value={phone}
            {...getValidationProps('phone', isDeedOwner)}
          />

          <p className={phoneTextClasses}>
            {getLocalization(
              'Please add a valid mobile phone number where you can receive text message'
            )}
          </p>
        </div>

        <Input
          autoComplete="email"
          isDisabled={isInputDisabled}
          inputMode="email"
          label={`${getLocalization('Email')}*`}
          maxLength={InputMaxLength.MAX_LENGTH_EMAIL}
          onBlur={listHandleBlur.email}
          onChange={listHandleChange.email}
          type="email"
          value={email}
          {...getValidationProps('email', isDeedOwner)}
        />
      </div>
    </section>
  );
});

export default PersonForm;
