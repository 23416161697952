import React, { FormEvent } from 'react';
import classNames from 'classnames';

import ExternalLink from 'src/components/common/external-link';

import type { CheckboxProps } from './checkbox.props';

import './checkbox.scss';

function Checkbox({
  id,
  isChecked,
  isDisabled,
  isError,
  isSemiState,
  label,
  linkLabel,
  linkUrl = '#',
  linkCollection,
  onChange,
}: CheckboxProps) {
  const isWithLinkCollections = linkCollection?.length;

  const className = classNames('checkbox', {
    checkbox_checked: isChecked,
    checkbox_disabled: isDisabled,
    checkbox_error: isError,
    checkbox_semi: isSemiState,
  });

  const textsClasses = classNames(
    'checkbox__texts',
    isWithLinkCollections && 'checkbox__texts_with-links'
  );

  const handleChange = (evt: FormEvent<HTMLInputElement>) => onChange(evt.currentTarget.checked);

  const renderLinks = () => {
    if (isWithLinkCollections) {
      return (
        <span className="checkbox__link-container">
          {linkCollection.map(({ linkUrl, linkLabel }, index) => {
            if (index !== linkCollection.length - 1) {
              return (
                <React.Fragment key={linkLabel}>
                  <ExternalLink
                    className="checkbox__link"
                    ariaLabel={linkLabel}
                    href={linkUrl}
                    tabIndex={isDisabled ? -1 : 0}
                  >
                    {linkLabel}
                  </ExternalLink>
                  <span className="checkbox__link-and"> & </span>
                </React.Fragment>
              );
            }

            return (
              <ExternalLink
                className="checkbox__link"
                key={linkLabel}
                ariaLabel={linkLabel}
                href={linkUrl}
                tabIndex={isDisabled ? -1 : 0}
              >
                {linkLabel}
              </ExternalLink>
            );
          })}
        </span>
      );
    }

    if (linkUrl && linkLabel && !isWithLinkCollections) {
      return (
        <ExternalLink
          className="checkbox__link"
          ariaLabel={linkLabel}
          href={linkUrl}
          tabIndex={isDisabled ? -1 : 0}
        >
          {linkLabel}
        </ExternalLink>
      );
    }
  };

  return (
    <div className={className}>
      <input
        className="checkbox__input"
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleChange}
        disabled={isDisabled}
      />

      <span className={textsClasses}>
        <label className="checkbox__label" htmlFor={id}>
          <span className="checkbox__label-icon" aria-hidden>
            <span className="checkbox__label-icon-wrapper" aria-hidden />
          </span>
          <span className="checkbox__texts-container">
            <span className="checkbox__label-text">{label}</span>
            {renderLinks()}
          </span>
        </label>
      </span>
    </div>
  );
}

export default Checkbox;
